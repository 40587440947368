const API_SERVER = process.env.REACT_APP_API_SERVER;

export const getDocumentTypes = async () => {
    const response = await fetch(`${API_SERVER}/admin-setup/document-types`, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        }
    });

    const responseData = await response.json();

    return responseData;
};
