export default function FormResetButton(props) {
    const { onClick, label } = props;
    return (
        <button
            type="button"
            onClick={onClick}
            className="mr-2 rounded-md border border-gray-300 bg-white py-2 px-4 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-dl-accent focus:ring-offset-2"
        >
            {label}
        </button>
    );
}
