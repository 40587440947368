export default function FormCheckBox(props) {
    const { identifier, checked, label, registration, helperText, errorMessage } = props;

    return (
        <div key={identifier} className="flex items-start">
            <div className="h-5 flex items-center">
                <input
                    id={identifier}
                    name={identifier}
                    type="checkbox"
                    defaultChecked={checked}
                    className="focus:ring-dl-primary-500 h-4 w-4 text-dl-secondary border-gray-300 rounded"
                    {...registration}
                />
            </div>
            <div className="ml-3 text-sm">
                <label htmlFor={identifier} className="font-medium text-gray-700">
                    {label}
                </label>
                {!errorMessage && helperText && <p className="text-gray-500">{helperText}</p>}
                {errorMessage ? <p className="mt-2 text-sm text-red-700">{errorMessage}</p> : null}
            </div>
        </div>
    );
}
