export default function FormTextArea(props) {
    const { label, identifier, rows, placeholder, helperText, registration, errorMessage, disabled, value, onChange } = props;

    return (
        <>
            <label htmlFor="governmentPermit" className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <div className="mt-1">
                <textarea
                    id={identifier}
                    name={identifier}
                    rows={rows}
                    className="shadow-sm focus:ring-dl-primary-500 focus:border-dl-primary-500 mt-1 block w-full sm:text-sm border border-gray-300 rounded-md"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    {...registration}
                    disabled={disabled}
                />
            </div>
            {!errorMessage && helperText && <p className="mt-2 text-sm text-gray-500">{helperText}</p>}
            {errorMessage ? <p className="mt-2 text-sm text-red-700">{errorMessage}</p> : null}
        </>
    );
}
