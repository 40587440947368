export default function FormButton(props) {
    const { label, type, onClick, disabled } = props;

    return (
        <button
            type={type}
            onClick={onClick}
            disabled={disabled}
            className="bg-dl-primary-700 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-dl-primary-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-dl-primary-500 disabled:bg-dl-primary-500"
        >
            {label}
        </button>
    );
}
