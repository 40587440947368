export default function FormFieldset(props) {
    const { legend, errorMessage, children } = props;
    return (
        <div className="col-span-6 sm:col-span-4">
            <fieldset>
                <legend className="text-base font-medium text-gray-900">{legend}</legend>
                <div className="mt-4 space-y-4">{children}</div>
                {errorMessage ? <p className="mt-2 text-sm text-red-700">{errorMessage}</p> : null}
            </fieldset>
        </div>
    );
}
