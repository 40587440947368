export default function ControlledFormSelectDrop(props) {
    const {
        label,
        identifier,
        autoComplete,
        options,
        helperText,
        errorMessage,
        defaultValue,
        value,
        onChange,
        disabled,
        emptyValue = ' -- Seleccione '
    } = props;

    return (
        <>
            <label htmlFor={identifier} className="block text-sm font-medium text-gray-700">
                {label}
            </label>
            <select
                id={identifier}
                name={identifier}
                autoComplete={autoComplete}
                value={value}
                defaultValue={defaultValue}
                onChange={onChange}
                disabled={disabled}
                className="mt-1 block w-full bg-white border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-dl-primary-500 focus:border-dl-primary-500 sm:text-sm"
            >
                <option key="empty-select-value" value="">
                    {emptyValue}
                </option>
                {options &&
                    options.map((opt) => {
                        return (
                            <option key={opt.key || opt.id} value={opt.id} disabled={opt.disabled}>
                                {opt.label}
                            </option>
                        );
                    })}
            </select>
            {!errorMessage && helperText && (
                <p className="mt-2 text-sm text-gray-500" id={`${identifier}-description`}>
                    {helperText}
                </p>
            )}
            {errorMessage ? <p className="mt-2 text-sm text-red-700">{errorMessage}</p> : null}
        </>
    );
}
